<template>
  <v-app>
    <topToolBar />
    <sideBar />
    <v-container>
      <v-dialog v-model="accountRemoveDialog" max-width="800">
        <v-card>
          <v-card-title class="headline"
            >Please enter remove UID: {{ removeID }}</v-card-title
          >
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-text-field
                  v-model="confirmRemoveID"
                  class="mx-4"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  elevation="2"
                  :disabled="removeID != confirmRemoveID"
                  @click="removeUser"
                  >remove</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="noAuthDialog" persistent width="300">
        <v-card>
          <v-card-title class="headline grey lighten-2"> 已登出 </v-card-title>
          <v-card-text class="my-4"> 請返回登入頁面 </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" href="/admin/login" text> 確定 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="passportDialog" max-width="800">
        <v-card>
          <v-card-title class="headline"
            >Passport: {{ passportNumber }}</v-card-title
          >
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-progress-circular
                  :size="50"
                  :width="7"
                  color="purple"
                  indeterminate
                  justify="center"
                  align="center"
                  v-if="passportImg == ''"
                ></v-progress-circular>
              </v-col>
              <v-col align="center" justify="center">
                <v-img
                  align="center"
                  justify="center"
                  v-if="passportImg != ''"
                  :src="passportImg"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card class="mt-16">
        <v-data-table
          :search="search"
          :headers="tableHeader"
          :items="users"
          item-key="UID"
          :loading="loadingList"
        >
          <template v-slot:body.append>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="search"
                  label="搜尋用戶"
                  append-icon="mdi-magnify"
                  class="mx-4"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
         <!--  <template v-slot:item.passport="{ item }">
            <v-icon small class="mr-2" @click="showPassport(item)">
              mdi-aspect-ratio
            </v-icon>
          </template> -->
          <!-- <template v-slot:item.sendEmail="{ item }">
            <v-icon small class="mr-2" @click="sendEmail(item)">
              mdi-email
            </v-icon>
          </template> -->
          <template v-slot:item.lock="{ item }">
            <a @click="lock(item)" v-if="!item.locked">封鎖</a>
            <a @click="lock(item)" v-if="item.locked">解鎖</a>
          </template>
          <template v-slot:item.delete="{ item }">
            <v-icon small class="mr-2" @click="showAccountRemove(item)">
              mdi-account-remove
            </v-icon>
          </template>
          <template v-slot:item.coins="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small class="mr-2">
                  mdi-bitcoin
                </v-icon>
              </template>
              <v-row no-gutters style="width: 300px; margin: 4px; padding: 4px">
                <v-col cols="6" v-for="coin in item.coins" :key="coin.symbol">
                  {{ coin.symbol }}:{{ coin.amount.toFixed(3) }}
                </v-col>
              </v-row>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import AuthService from "../api/authService.js";
import UserService from "../api/userService.js";
import sideBar from "../components/sideBar.vue";
import topToolBar from "../components/topToolBar.vue";
import axios from "../axios"
export default {
  components: {
    sideBar,
    topToolBar,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      search: "",
      users: [],
      selectedIndex: 0,
      selectedItem: 0,
      passportImg: "",
      passportNumber: "",
      removeID: "",
      confirmRemoveID: "",
      noAuthDialog: false,
      passportDialog: false,
      accountRemoveDialog: false,
      loadingList: true,
    };
  },
  computed: {
    tableHeader() {
      return [
        {
          text: "UID",
          value: "user_id",
        },
        {
          text: "用戶名",
          value: "username",
        },
        {
          text: "姓氏",
          value: "last_name",
        },
        {
          text: "名字",
          value: "first_name",
        },
        {
          text: "電郵地址",
          value: "email",
        },
     /*    {
          text: "USDT",
          value: "USDT",
        },
        {
          text: "幣",
          value: "coins",
        },
        {
          text: "鎖",
          value: "lock",
          sortable: false,
        }, */
  /*       {
          text: "Send Email",
          value: "sendEmail",
          sortable: false,
        }, */
  /*       {
          text: "護照圖片",
          value: "passport",
          sortable: false,
        }, */
      /*   {
          text: "Delete",
          value: "delete",
          sortable: false,
        }, */
      ];
    },
  },
  methods: {
    showAccountRemove(item) {
      this.removeID = item.UID;
      this.accountRemoveDialog = true;
    },
    removeUser() {
      if (this.removeID == this.confirmRemoveID) {
        this.accountRemoveDialog = false;
        this.loadingList = true;
        AuthService.userRemove(this.removeID).then((response) => {
          if (!response.data.status) {
            this.noAuthDialog = true;
          } else {
            UserService.getAllUser().then((response) => {
              this.users = response.data;
              this.loadingList = false;
            });
          }
        });
      }
    },
    lock(item) {
      this.loadingList = true;
      if (item.locked) {
        UserService.lockControl(item.UID, "unlock").then((response) => {
          if (!response.data.status) {
            this.noAuthDialog = true;
          } else {
            UserService.getAllUser().then((response) => {
              this.users = response.data;
              this.loadingList = false;
            });
          }
        });
      } else {
        UserService.lockControl(item.UID, "lock").then((response) => {
          if (!response.data.status) {
            this.noAuthDialog = true;
          } else {
            UserService.getAllUser().then((response) => {
              this.users = response.data;
              this.loadingList = false;
            });
          }
        });
      }
    },
    sendEmail(item) {
      this.$router.push({
        path: "EmailPanel",
        query: { emailTo: item.Email, lastname: item.LastName },
      });
    },
    showPassport(item) {
      this.passportNumber = "";
      this.passportImg = "";
      this.selectedIndex = this.users.indexOf(item);
      this.selectedItem = Object.assign({}, item);
      console.log(item);
      UserService.getPassportImage(item.UID).then((response) => {
        console.log(response.data);
        if (!response.data.status) {
          this.noAuthDialog = true;
        } else {
          console.log(response.data);
          this.passportImg = response.data.PassportImage;
          this.passportNumber = response.data.Passport;
        }
      });
      console.log(222);
      this.passportDialog = true;
    },
    async getAdminAuth () {
     let res = await axios.get("/admin/allUsers");
console.log(res.data)
this.users = res.data.users;
this.loadingList = false;
     /*  AuthService.adminAuth().then((response) => {
        console.log(response.data.message);
        if (!response.data.status) {
          this.noAuthDialog = true;
        } else {
          UserService.getAllUser().then((response) => {
            this.users = response.data;
            this.loadingList = false;
          });
        }
      }); */
    },
  },
  created() {
    this.getAdminAuth();
  },
};
</script>
<style>