const url = 'https://api.hashlever.com/';
import axios from 'axios'

class EmailService {
    static sendEmail(emailTo, subject, content) {
        return axios.post(url + "auth/sendEmail", {
            emailTo: emailTo,
            subject: subject,
            content: content
        })
    }
    static getEmail() {
        return axios.post(url + "auth/getEmail")
    }
}
export default EmailService