<template>
  <v-app>
    <topToolBar />
    <sideBar />
    <v-container>
      <!-- auth dialog-->
      <v-dialog v-model="noAuthDialog" persistent width="300">
        <v-card>
          <v-card-title class="headline grey lighten-2"> 已登出 </v-card-title>
          <v-card-text class="my-4"> 請返回登入頁面 </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" href="/admin/login" text> 確定 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- image dialog-->
      <v-dialog v-model="imageDialog" max-width="800">
        <v-card>
          <v-card-title class="headline">Image</v-card-title>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <v-img align="center" justify="center" v-if="image != ''" :src="image" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- confirm dialog-->
      <v-dialog v-model="confirmDialog" max-width="900">
        <v-card>
          <v-card-title class="headline">確定</v-card-title>
          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12" align="left" justify="center">
                    <v-card-text>
                      UID: {{ approveUID }}<br />
                      單ID: {{ approveID }}<br />

                      數量: {{ approveAmount }}<br />
                      Action: {{ approveAction }}
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" align="center" justify="center">
                    <v-btn
                      @click="closeConfirmDialog"
                      class="mx-4 my-4"
                      color="error"
                      dark
                    >
                      取消</v-btn
                    >
                    <v-btn
                      v-if="this.approveAction == 'reject'"
                      @click="confirmAction"
                      class="mx-4 my-4"
                      color="primary"
                      dark
                      >確定</v-btn
                    >
                    <v-btn
                      v-if="this.approveAction == 'approve'"
                      @click="confirmAction"
                      class="mx-4 my-4"
                      color="primary"
                      dark
                      >確定USDT</v-btn
                    >
                    <!--       <v-btn
                      v-if="this.approveAction == 'approve'"
                      @click="confirmETHAction"
                      class="mx-4 my-4"
                      color="primary"
                      dark
                      >確定ETH</v-btn
                    > -->
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" align="center" justify="center">
                <v-img
                  contain
                  align="center"
                  justify="center"
                  v-if="image != ''"
                  :src="image"
                  max-width="800"
                  max-height="400"
                />
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- edit dialog-->
      <v-dialog v-model="editDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">更改</v-card-title>
          <v-card-actions>
            <v-text-field v-model="editValue"></v-text-field>
          </v-card-actions>
          <v-card-actions>
            <v-btn @click="closeEdit">cancel</v-btn>
            <v-btn @click="editAction">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card class="mt-16">
        <v-data-table
          :search="search"
          :headers="tableHeader"
          :items="depositList"
          item-key="_id"
          :loading="loadingList"
        >
          <template v-slot:footer>
            <v-row>
              <v-col style="margin: 2px; padding: 2px">
                <v-text-field
                  v-model="search"
                  label="搜尋用戶"
                  append-icon="mdi-magnify"
                  class="mx-8"
                  style="width: 400px"
                ></v-text-field>

                <v-checkbox
                  v-model="filterRejected"
                  label="Don't show rejected"
                  class="mx-8"
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.uploadImage="{ item }">
            <v-icon small class="mr-2" @click="showImage(item)">
              mdi-aspect-ratio
            </v-icon>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-icon
              v-if="item.status == 'pending'"
              small
              class="mr-2"
              @click="showEdit(item)"
            >
              mdi-file-document-edit-outline
            </v-icon>
          </template>
          <template v-slot:item.approve="{ item }">
            <v-icon
              v-if="item.status == 'pending'"
              small
              class="mr-2"
              @click="showConfirm(item, 'approve')"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-if="item.status == 'pending'"
              small
              class="mr-2"
              @click="showConfirm(item, 'reject')"
            >
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import DepositService from "../api/depositService.js";
import sideBar from "../components/sideBar.vue";
import topToolBar from "../components/topToolBar.vue";
import axios from "../axios";
export default {
  components: {
    sideBar,
    topToolBar,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      loadingList: true,
      depositList: [],
      noAuthDialog: false,
      imageDialog: false,
      confirmDialog: false,
      editDialog: false,
      image: "",
      approveUID: "",
      approveID: "",
      approveAddress: "",
      approveAmount: "",
      approveAction: "",
      editID: "",
      editValue: 0,
      search: "",
      filterRejected: true,
    };
  },
  computed: {
    tableHeader() {
      return [
        {
          text: "UID",
          value: "user_id",
          sortable: false,
        },
        {
          text: "單ID",
          value: "deposit_id",
          sortable: false,
        },

        {
          text: "數量",
          value: "amount",
          sortable: false,
        },
        {
          text: "日期",
          value: "dateParse",
          sortable: false,
        },
        {
          text: "狀態",
          value: "status",
          filter: (value) => {
            console.log(value);
            if (!this.filterRejected) {
              return true;
            } else {
              return value != "rejected";
            }
          },
        },

        {
          text: "證明圖片",
          value: "uploadImage",
          sortable: false,
        },
        {
          text: "更改",
          value: "edit",
          sortable: false,
        },
        {
          text: "批准",
          value: "approve",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    confirmAction() {
      axios
        .post("/admin/approveDeposit", {
          deposit_id: this.approveID,
          approved: this.approveAction == "approve" ? true : false,
        })
        .then(() => {
          this.confirmDialog = false;
          this.loadData();
        });
    },
    confirmETHAction() {
      console.log("123");
      DepositService.approveDeposit(this.approveID, "approveETH").then(() => {
        this.confirmDialog = false;
        this.loadData();
      });
    },
    async editAction() {
      await axios.post("/admin/editDeposit", {
        deposit_id: this.editID,
        amount: this.editValue,
      });
      this.editDialog = false;
      this.loadData();
      /*    DepositService.editDeposit(this.editID, this.editValue).then(() => {
        this.editDialog = false;
        this.loadData();
      }); */
    },
    closeEdit() {
      this.editDialog = false;
    },
    showEdit(item) {
      this.editID = item.deposit_id;
      this.editValue = item.amount;
      this.editDialog = true;
    },
    showConfirm(item, action) {
      this.approveUID = item.user_id;
      this.approveID = item.deposit_id;
      this.approveAmount = item.amount;
      this.approveAddress = item.address;
      this.approveAction = action;
      this.confirmDialog = true;
      this.image = "";
      DepositService.getDepositImage(item._id).then((response) => {
        if (!response.data.status) {
          this.noAuthDialog = true;
        } else {
          this.image = response.data.image;
        }
      });
    },
    async showImage(item) {
      this.image = "";
      console.log(item);
      let res = await axios.post("/admin/getDepositImage", {
        img_id: item.img,
      });
      this.image = res.data.img;
      console.log(res.data);
      /*      DepositService.getDepositImage(item._id).then((response) => {
        if (!response.data.status) {
          this.noAuthDialog = true;
        } else {
          this.image = response.data.image;
        }
      }); */
      this.imageDialog = true;
    },
    loadData() {
      this.loadingList = true;
      axios.get("/admin/deposit").then((res) => {
        console.log(res.data);
        this.depositList = res.data.deposit.reverse();
        this.loadingList = false;
      });
      /*  DepositService.getAllDeposit().then((response) => {
        if (response.data.status) {
          console.log(response.data.result);
          var array = response.data.result;
          array.forEach((element, i) => {
            array[i].dateParse = new Date(Date.parse(element.date));
          });
          array.reverse();
          this.depositList = array;
          this.loadingList = false;
        } else {
          this.noAuthDialog = true;
        }
      }); */
    },
  },
  created() {
    //  this.getCoinOverview();
    console.log();
  },
  mounted() {
    this.loadData();
  },
  beforeDestroy() {},
};
</script>
<style>
.priceDown {
  max-width: 170px;
  padding: 4px;
  border-color: #ff3333;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  background-color: #ff3333;
  color: white;
}
.priceUp {
  max-width: 170px;
  padding: 4px;
  border-color: #00e600;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  background-color: #00e600;
  color: white;
}
</style>
