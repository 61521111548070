<template>
  <v-app>
    <topToolBar />
    <sideBar />
    <v-container>
      <v-dialog v-model="emailDialog" max-width="600">
        <v-card>
          <v-card-title class="headline">Mail</v-card-title>
          <v-card-text class="my-4">
            From: {{ dialogFrom }}<br />
            Time: {{ dialogTime }} <br />
            Subject: {{ dialogSubject }}<br />
            Content: {{ dialogContent }}
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card class="mx-auto">
        <v-toolbar color="blue" dark>
          <v-toolbar-title>Inbox</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list two-line>
          <v-list-item-group>
            <template v-for="(item, index) in items">
              <v-list-item :key="item.subject" @click="emailClick(item)">
                <template>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.subject"
                    ></v-list-item-title>

                    <v-list-item-subtitle
                      class="text--primary"
                      v-text="item.from"
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="item.receivedDate"
                    ></v-list-item-action-text>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider
                v-if="index < items.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import sideBar from "../components/sideBar.vue";
import topToolBar from "../components/topToolBar.vue";
import EmailService from "../api/emailService.js";
export default {
  components: {
    sideBar,
    topToolBar,
  },

  data() {
    return {
      emailDialog: false,
      dialogTime: "",
      dialogFrom: "",
      dialogSubject: "",
      dialogContent: "",
      items: [],
    };
  },
  computed: {},
  methods: {
    emailClick(args) {
      this.emailDialog = true;
      this.dialogTime = args.receivedDate;
      this.dialogFrom = args.from;
      this.dialogSubject = args.subject;
      this.dialogContent = args.content;
    },
    getEmail() {
      EmailService.getEmail().then((response) => {
        this.items = response.data.result;
        console.log(response.data);
      });
    },
  },
  created() {
    //  this.getCoinOverview();
  },
  mounted() {
      this.getEmail()
  },
  beforeDestroy() {},
};
</script>
<style>
</style>