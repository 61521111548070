<template>
  <SideBar/>
</template>
<script>
import SideBar from '../components/sideBar.vue'
export default {

  components: {
      SideBar
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {

  }
};
</script>
<style>