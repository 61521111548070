<template>
  <v-container
    class="fill-height"
    fluid
    align="center"
    justify="center"
    :style="{ backgroundImage: `url('${backgroundImg}')` }"
    style="background-position: center; background-size: cover"
  >
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="4" xl="3">
        <v-card class="elevation-12">
          <v-toolbar color="#34558b" dark flat>
            <v-toolbar-title>Login</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="username"
                label="username"
                prepend-icon="person"
                :rules="rules.username"
                autocomplete="username"
                v-on:keyup.enter="login"
              />
              <v-text-field
                v-model="password"
                label="password"
                prepend-icon="lock"
                type="password"
                :rules="rules.password"
                autocomplete="current-password"
                v-on:keyup.enter="login"
              />
              <!--   <v-text-field
                v-model="token2fa"
                label="2FA Token"
                prepend-icon="mdi-lock-clock"
                autocomplete="current-password"
                v-on:keyup.enter="login"
              /> -->
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              name="submit"
              @click="login"
              :loading="isLoading"
              :disabled="isDisabled"
              >login</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <template>
      <div class="text-center ma-2">
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
          >{{ snackbar.message }}
        </v-snackbar>
      </div>
    </template>
  </v-container>
</template>
<script>
import axios from "../axios";
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      backgroundImg: require("../assets/img/foster-lake.jpg"),
      username: "",
      password: "",
      token2fa: "",
      response: "",
      isLoading: false,
      confirm: {
        username: false,
        password: false,
      },
      rules: {
        username: [(v) => !!v || "請輸入用戶名", (v) => (this.confirm.username = !!v)],
        password: [(v) => !!v || "請輸入密碼", (v) => (this.confirm.password = !!v)],
      },
      snackbar: {
        visible: false,
        message: "",
        color: "",
      },
    };
  },
  methods: {
    ...mapMutations(["authSetter"]),
    login: function () {
      this.isloading = true;
      if (this.isDisabled) return;
      this.snackbar.visible = true;
      this.snackbar.color = "info";
      this.snackbar.message = "登入中...";
      axios
        .create({
          baseURL: process.env.VUE_APP_BACKENDAPI,
        })
        .post("/admin/login", {
          email: this.username,
          password: this.password,
        })
        .then((response) => {
          if (response.data.status === true) {
            this.snackbar.color = "success";
            this.snackbar.message = response.data.message;
            console.log(response.data);
            this.authSetter({ isLoggedIn: true, token: response.data.token });
           console.log(this.authGetter)
            this.$router.push({ name: "Dashboard" });
          } else {
            this.snackbar.visible = true;
            this.snackbar.color = "error";
            this.snackbar.message = response.data.message;
          }
        });
      /*  AuthService.adminLogin(this.username, this.password, this.token2fa).then(
        (response) => {
          console.log(response);
          if (response.data.status === true) {
            this.snackbar.color = "success";
            this.snackbar.message = response.data.message;
            console.log(response);
            sessionStorage.setItem("token", response.data.token);
            sessionStorage.setItem("account", response.data.user);
            this.$router.push({ name: "Dashboard" });
          } else {
            this.snackbar.visible = true;
            this.snackbar.color = "error";
            this.snackbar.message = response.data.message;
          }
        }
      ); */
    },
  },
  computed: {
    ...mapGetters(['authGetter']),
    isDisabled() {
      return this.confirm.username && this.confirm.password ? false : true;
    },
  },
  created() {},
};
</script>
