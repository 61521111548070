<template>
  <v-app>
    <topToolBar />
    <sideBar />
    <v-container>
      <v-card elevation="11" outlined style="padding: 10px; margin: 10px">
        <v-card-title class="mx-4 my-2 text-h5"
          ><v-icon class="mx-2">mdi-email-outline</v-icon>覆Email</v-card-title
        >
        <v-text-field
          class="mx-4"
          disabled
          label="From: "
          value="cs@hashlever.com"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="emailTo"
          :rules="rules.emailTo"
          class="mx-4"
          label="To: "
          outlined
        ></v-text-field>
        <v-text-field
          v-model="emailSubject"
          :rules="rules.subject"
          class="mx-4"
          label="Subject: "
          outlined
        ></v-text-field>
        <v-textarea
          outlined
          auto-grow
          name="input-7-4"
          label="Email Content"
          class="mx-4 my-2"
          v-model="emailContent"
          :rules="rules.content"
        ></v-textarea>
        <v-col align="right">
          <v-btn
            :disabled="buttonDisable"
            :loading="buttonDisable"
            color="primary"
            width="150"
            height="50"
            @click="sendEmail"
            >Send</v-btn
          >
        </v-col>
      </v-card>
      <v-snackbar v-model="snackbar">
        {{ returnMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import sideBar from "../components/sideBar.vue";
import topToolBar from "../components/topToolBar.vue";
import EmailService from "../api/emailService.js";
export default {
  components: {
    sideBar,
    topToolBar,
  },

  data() {
    return {
      returnMessage: "Success",
      snackbar: false,
      buttonDisable: false,
      confirm: {
        emailTo: false,
        subject: false,
        content: false,
      },
      rules: {
        emailTo: [
          (v) => !!v || "Please enter email address",
          (v) => (this.confirm.emailTo = !!v),
        ],
        subject: [
          (v) => !!v || "Please enter subject",
          (v) => (this.confirm.subject = !!v),
        ],
        content: [
          (v) => !!v || "Please enter content",
          (v) => (this.confirm.content = !!v),
        ],
      },
      emailTo: "",
      emailSubject: "",
      emailContent: "Dear Customer\n\nBest regards,\nThe Hashlever CS team",
    };
  },
  computed: {},
  methods: {
    sendEmail() {
      if (
        this.confirm.emailTo &&
        this.confirm.subject &&
        this.confirm.content
      ) {
        this.buttonDisable = true;
        EmailService.sendEmail(
          this.emailTo,
          this.emailSubject,
          this.emailContent
        ).then((response) => {
          if (response.data.status == true) {
            this.emailTo = "";
            this.emailSubject = "";
            this.emailContent = "";
            this.buttonDisable = false;
            this.returnMessage = "Success";
            this.snackbar = true;
          } else {
            this.buttonDisable = false;
            this.returnMessage = "Failed";
            this.snackbar = true;
          }
        });
      }
    },
  },
  created() {
    //  this.getCoinOverview();
  },
  mounted() {
    if (this.$route.query.emailTo) {
      this.emailTo = this.$route.query.emailTo;
    }
    if (this.$route.query.lastname) {
      this.emailContent =
        "Dear Mr/Ms " +
        this.$route.query.lastname +
        "\n\nBest regards,\nThe Hashlever CS team";
    }
  },
  beforeDestroy() {},
};
</script>
<style>
</style>