const url = 'https://api.hashlever.com/';
import axios from 'axios'

class DepositService {
  static getDepositImage(id) {
    return axios.post(url + "deposit/getDepositImage", {
      id: id,
    })
  }
  static getAllDeposit() {
    return axios.post(url + "deposit/getAllDeposit")
  }
  static approveDeposit(id, action) {
    return axios.post(url + "deposit/approveDeposit", {
      id: id,
      action: action
    })
  }
  static editDeposit(id, amount) {
    return axios.post(url + "deposit/editDeposit", {
      id: id,
      amount: amount
    })
  }

}
export default DepositService