import Vue from 'vue'
import Vuex from 'vuex'
import createPresistedState from "vuex-persistedstate"
Vue.use(Vuex)

const state = {
    loggedIn: false,
    auth: {
        isLoggedIn: false,
        token: ""
    },
}
const getters = {
    authGetter: (state) => state.auth,
}

const mutations = {
    changeLogin(state, { result }) {
        state.login = result;
    },
    tokenSetter(state, data) {
        state.auth.token = data;
    },
    authSetter(state, data) {
        state.auth = data
    },

}

const actions = {
    checkLoggedIn() {
        return true
        /*       axios.post(url + "auth/adminAuth").then((response) => {
                  if (!response.data.status) {
                      router.push({ name: 'Login' })
                      return
                  }
                  commit('changeLogin', response.data.status)
              }) */
    }
}

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    plugins: [
        createPresistedState({
            storage: window.localStorage
        })
    ]
})