const url = 'https://api.hashlever.com/';
import axios from 'axios'

class AuthService {
  static adminLogin(Username, Password, token2FA) {
    return axios.post(url + "auth/adminPageLogin", {
      Username: Username,
      Password: Password,
      token2FA: token2FA
    })
  }
  static userRegister(Email, Username, Firstname, Lastname, Password) {
    return axios.post(url + "auth/userRegister", {
      Email: Email,
      Username: Username,
      Firstname: Firstname,
      Lastname: Lastname,
      Password: Password
    })
  }
  static userRemove(UID) {
    return axios.post(url + "auth/userRemove", {
      UID: UID,
    })
  }
  static adminAuth() {
    return axios.post(url + "auth/adminAuth")
  }
}
export default AuthService