const url = 'https://api.hashlever.com/';
import axios from 'axios'

class StakingService {
    static getStakingDetail() {
        return axios.post(url + "staking/getStakingDetail")
    }
    static setStakingDetail(data) {
        return axios.post(url + "staking/setStakingDetail", {
            data: data
        })
    }
}


export default StakingService