<template>
  <v-app>
    <topToolBar />
    <sideBar />
    <v-container>
      <!-- auth dialog-->
      <v-dialog v-model="noAuthDialog" persistent width="300">
        <v-card>
          <v-card-title class="headline grey lighten-2"> 已登出 </v-card-title>
          <v-card-text class="my-4"> 請返回登入頁面 </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" href="/admin/login" text> 確定 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- confirm dialog-->
      <v-dialog v-model="confirmDialog" max-width="500">
        <v-card>
          <v-card-title class="headline">確定</v-card-title>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" align="left" justify="center">
                    <v-card-text>
                      UID: {{ approveUID }}<br />
                      單ID: {{ approveID }}<br />
                      address: {{ approveAddress }}<br />
                      數量: {{ approveAmount }}<br />
                      Action: {{ approveAction }}
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" align="center" justify="center">
                    <v-btn
                      @click="closeConfirmDialog"
                      class="mx-4 my-4"
                      x-large
                      color="error"
                      dark
                    >
                      取消</v-btn
                    >
                    <v-btn
                      @click="confirmAction"
                      class="mx-4 my-4"
                      x-large
                      color="primary"
                      dark
                      >確定</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- edit dialog-->
      <v-dialog v-model="editDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">更改</v-card-title>
          <v-card-actions>
            <v-text-field v-model="editValue"></v-text-field>
          </v-card-actions>
          <v-card-actions>
            <v-btn @click="closeEdit">cancel</v-btn>
            <v-btn @click="editAction">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card class="mt-16">
        <v-data-table
          :search="search"
          :headers="tableHeader"
          :items="withdrawalList"
          item-key="_id"
          :loading="loadingList"
        >
          <template v-slot:body.append>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="search"
                  label="搜尋用戶"
                  append-icon="mdi-magnify"
                  class="mx-4"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.uploadImage="{ item }">
            <v-icon small class="mr-2" @click="showImage(item)">
              mdi-aspect-ratio
            </v-icon>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-icon
              v-if="item.status == 'pending'"
              small
              class="mr-2"
              @click="showEdit(item)"
            >
              mdi-file-document-edit-outline
            </v-icon>
          </template>
          <template v-slot:item.approve="{ item }">
            <v-icon
              v-if="item.status == 'pending'"
              small
              class="mr-2"
              @click="showConfirm(item, 'approve')"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-if="item.status == 'pending'"
              small
              class="mr-2"
              @click="showConfirm(item, 'reject')"
            >
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import sideBar from "../components/sideBar.vue";
import topToolBar from "../components/topToolBar.vue";
import axios from "../axios";
export default {
  components: {
    sideBar,
    topToolBar,
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      loadingList: true,
      withdrawalList: [],
      noAuthDialog: false,
      imageDialog: false,
      confirmDialog: false,
      editDialog: false,
      image: "",
      approveUID: "",
      approveID: "",
      approveAddress: "",
      approveAmount: "",
      approveAction: "",
      editID: "",
      editValue: 0,
      search: "",
    };
  },
  computed: {
    tableHeader() {
      return [
        {
          text: "UID",
          value: "user_id",
          sortable: false,
        },
        {
          text: "單ID",
          value: "withdrawal_id",
          sortable: false,
        },
        {
          text: "Address",
          value: "address",
          sortable: false,
        },
        {
          text: "數量",
          value: "amount",
          sortable: false,
        },
        {
          text: "日期",
          value: "dateParse",
          sortable: false,
        },
        {
          text: "狀態",
          value: "status",
        },
        /* {
          text: "更改",
          value: "edit",
          sortable: false,
        }, */
        {
          text: "批准",
          value: "approve",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    async confirmAction() {
      await axios.post("/admin/approveWithdrawal", {
        withdrawal_id: this.approveID,
        approve: this.approveAction == "approve",
      });
      this.confirmDialog = false;
      this.loadData();

     /*  WithdrawalService.approveWithdrawal(this.approveID, this.approveAction).then(() => {
        this.confirmDialog = false;
        this.loadData();
      }); */
    },
    showConfirm(item, action) {
      this.approveUID = item.user_id;
      this.approveID = item.withdrawal_id;
      this.approveAmount = item.amount;
      this.approveAddress = item.address;
      this.approveAction = action;
      this.confirmDialog = true;
    },
    async loadData() {
      this.loadingList = true;
      let res = await axios.get("/admin/withdrawal");
      let array = res.data.withdrawal;
      array.forEach((element, i) => {
        array[i].dateParse = new Date(Date.parse(element.created_at));
      });
      array.reverse();
      this.withdrawalList = array;
      this.loadingList = false;
    },
  },
  created() {},
  mounted() {
    this.loadData();
  },
  beforeDestroy() {},
};
</script>
<style>
.priceDown {
  max-width: 170px;
  padding: 4px;
  border-color: #ff3333;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  background-color: #ff3333;
  color: white;
}
.priceUp {
  max-width: 170px;
  padding: 4px;
  border-color: #00e600;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  background-color: #00e600;
  color: white;
}
</style>
