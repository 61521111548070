<template>
  <v-app>
    <topToolBar />
    <sideBar />
    <v-container>
      <v-row align="center" justify="center">
        <v-col align="center" justify="center">
          <v-card style="max-width: 1000px" class="mx-4 my-4">
            <v-row align="center" justify="center">
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="staking in stakingData" :key="staking.name">
                        <td class="text-h5">
                          <div>
                            {{ staking.name }}
                          </div>
                        </td>
                        <td class="text-h5">
                          <div>
                            <v-text-field
                              v-model="staking.rate[0]"
                              type="number"
                              label="3 Days(%)"
                            ></v-text-field>
                          </div>
                        </td>
                        <td class="text-h5">
                          <div>
                            <v-text-field
                              v-model="staking.rate[1]"
                              type="number"
                              label="30 Days(%)"
                            ></v-text-field>
                          </div>
                        </td>
                        <td class="text-h5">
                          <div>
                            <v-text-field
                              v-model="staking.rate[2]"
                              type="number"
                              label="60 Days(%)"
                            ></v-text-field>
                          </div>
                        </td>
                        <td class="text-h5">
                          <div>
                            <v-text-field
                              v-model="staking.rate[3]"
                              type="number"
                              label="90 Days(%)"
                            ></v-text-field>
                          </div>
                        </td>
                        <td class="text-h5">
                          <div>
                            <v-text-field
                              v-model="staking.min"
                              type="number"
                              label="Min. amount"
                            ></v-text-field>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row
              ><v-col>
                <v-btn @click="changeStakingRate" elevation="2" x-large color="primary">
                  Submit
                </v-btn>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" align="center" justify="center"> </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
        <v-progress-circular
          v-if="snackbar.loading"
          :width="3"
          color="green"
          indeterminate
          class="mx-4"
        ></v-progress-circular>
        {{ snackbar.message }}
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import sideBar from "../components/sideBar.vue";
import topToolBar from "../components/topToolBar.vue";
import StakingService from "../api/stakingService.js";
export default {
  components: {
    sideBar,
    topToolBar,
  },

  data() {
    return {
      emailDialog: false,
      dialogTime: "",
      dialogFrom: "",
      dialogSubject: "",
      dialogContent: "",
      stakingData: [],
      snackbar: {
        visible: false,
        loading: false,
        color: "",
        message: "",
        timeout: "",
      },
    };
  },
  computed: {},
  methods: {
    changeStakingRate() {
      console.log(this.stakingData);
      StakingService.setStakingDetail(this.stakingData).then((response) => {
        if (response.data.status) {
          this.snackbar.visible = true;
          this.snackbar.loading = false;
          this.snackbar.timeout = 5000;
          this.snackbar.message = "success";
          this.snackbar.color = "green";
          this.getCoinOverview();
        }
      });
    },
    getStakingList() {
      StakingService.getStakingDetail().then((response) => {
        console.log(response);
        if (response.data) {
          var data = response.data.data;
          console.log(data);
          this.stakingData = data;
        } else {
          this.noAuthDialog = true;
        }
      });
    },
  },
  created() {
    //  this.getCoinOverview();
  },
  mounted() {
    this.getStakingList();
  },
  beforeDestroy() {},
};
</script>
<style></style>
