const url = 'https://api.hashlever.com/';
import axios from 'axios'

class UserService {
  static getAllUser() {
    return axios.post(url + "user/getAllUser")
  }
  static getPassportImage(UID) {
    return axios.post(url + "user/getPassportImage", {
      UID: UID
    })
  }
  static lockControl(UID, action) {
    return axios.post(url + "user/lockControl", {
      UID: UID,
      action: action,
    })
  }
}
export default UserService