import Vue from 'vue'

import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import UserPanel from '../views/UserPanel.vue'
import CoinPanel_HVAT from '../views/CoinPanel_HVAT.vue'
import DepositPanel from '../views/DepositPanel.vue'
import WithdrawalPanel from '../views/WithdrawalPanel.vue'
import EmailPanel from '../views/EmailPanel.vue'
import MailBox from '../views/MailBox.vue'
import StakingProfit from '../views/stakingProfit.vue'
import store from '../store/index.js'
Vue.use(VueRouter)


const routes = [

  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/userPanel',
    name: 'UserPanel',
    component: UserPanel,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/CoinPanel_HVAT',
    name: 'CoinPanel_HVAT',
    component: CoinPanel_HVAT,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/DepositPanel',
    name: 'DepositPanel',
    component: DepositPanel,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/WithdrawalPanel',
    name: 'WithdrawalPanel',
    component: WithdrawalPanel,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/EmailPanel',
    name: 'EmailPanel',
    component: EmailPanel,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mailbox',
    name: 'MailBox',
    component: MailBox,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stakingProfit',
    name: 'StakingProfit',
    component: StakingProfit,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.dispatch("checkLoggedIn")
  }
  next()

})

export default router
